import { useTranslation } from "react-i18next";
import Chart from "components/@score/Chart";
import { useRecoilState } from "recoil";
import assignmentStore from "store/assignment.store";
import ResultsModal from "components/@score/ResultsModal";
import { useState } from "react";
import usePostHog from '../hooks/usePostHog';

function FinalScore() {
  usePostHog()
  const [assignment] = useRecoilState(assignmentStore);
  const [show, setShow] = useState(false);
  const { passed, questions, details } = assignment as any;
  const { t } = useTranslation();
  return (
    <>
      {assignment.hasOwnProperty("questions") && (
        <ResultsModal
          show={show}
          setShow={setShow}
          data={{ questions, details }}
        />
      )}

      {assignment && (
        <div className="d-flex jsutify-content-center flex-column align-items-center ">
          {assignment.hasOwnProperty("score") && (
            <>
              <h2 className="mb-2 mt-4 mt-lg-0 heading-2 text-bold ">
                {t("RESULTS")}
              </h2>

              <h3 className="heading-3">{t("ANSWERS_SUMMERY")}</h3>
              <span
                className="mb-4 "
                
                onClick={() => setShow(true)}
                style={{ color: "#00ffff", cursor: "pointer" }}
              >
                {t("CHECK_ANSWERS")}
              </span>
              <Chart result={assignment} />
              {passed ? (
                <>
                  {" "}
                  <span className="mt-4  font-size-base line-height-1 text-bold text-center">
                    {t("CONGRATULATIONS_YOU_PASSED")}
                  </span>
                 {assignment.hasOwnProperty("track") && assignment.track==='DRN' ? "": <>
                 <span className="font-size-base text-center ">
                    {t("WE_WILL_SEND_CERTIFICATE")}
                  </span>
                  <span className="font-size-base mb-4 text-center">
                    {t("CHECK_YOUR_EMAIL")}
                  </span>
                 </>}
                </>
              ) : (
                <span className="mt-4  mb-4 font-size-base line-height-1 text-bold text-center">
                  {t("YOU_DID_NOT_PASS")}
                </span>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}

export default FinalScore;