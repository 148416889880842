import { useEffect } from "react";
import { useTransition, animated, useSpringRef } from "react-spring";

function Reveal({ children, index }: any) {
  const transRef = useSpringRef();
  const transitions = useTransition(index, {
    ref: transRef,
    keys: null,
    from: { opacity: 0, transform: "translate3d(100%,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: { opacity: 0, transform: "translate3d(-50%,0,0)" },
  });
  useEffect(() => {
    transRef.start();
  }, [index]);
  return transitions((style, i) => {
    return (
      <animated.div
        style={{
          ...style,
          position: "absolute",
          width: "100%",
          height: " 100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </animated.div>
    );
  });
}

export default Reveal;
