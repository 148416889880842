import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import i18n from "./locales/i18n";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";



const assignment = localStorage.getItem("assignment")

const lastExamStatus = { assignment: assignment ? JSON.parse(assignment) : null }

 
const user = {
  token: localStorage.getItem("token"),
  language: localStorage.getItem("i18nextLng"),
  ...lastExamStatus
}

const type = user.assignment?user.assignment['type']:"UNKNOWN"
 
const tokenInQuery = new URLSearchParams(window.location.search).get("token")
if (tokenInQuery) {
  localStorage.clear()
}

Sentry.init({
  environment: process.env.REACT_APP_ENVIRONMENT,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  release: "benchmark-app@" + process.env.npm_package_version,
  initialScope: {
    tags: { language: user.language, type},
    user,
  },
  

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
