import InputField from "components/@common/input/InputField";
import { useEffect, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { StringParam, useQueryParam } from "use-query-params";
import usePostHog from '../hooks/usePostHog';

function StartPage() {
  usePostHog()
  const [emailInQuery,]=useQueryParam("email",StringParam);
  const [value, setValue] = useState("");
  const [isValid, setValid] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    const regex = new RegExp(/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/g);
    setValid(regex.test(value));
  }, [value]);
 
  useEffect(() => {
    if (emailInQuery) {
      setValue(emailInQuery);
    }
  },[emailInQuery]);
  return (
    <Row className="d-flex flex-column  ">
      <Col className="col-12 col-lg-6 d-flex flex-column">
        <h2>{t("WHAT_IS_YOUR_EMAIL")}</h2>
        <InputField
          fullWidth
          placeholder={t("TYPE_YOUR_ANSWER_HERE")}
          value={value}
          setValue={setValue}
          error={!isValid}
          helperText={"Invalid email"}
        />
        <div className="mt-5 align-self-center  align-self-lg-start ">
          <Button
            {...(value.length > 0 && {
              as: "a",
              href: `${
                process.env.REACT_APP_API_URL
              }/assignments/admission/${value}?${
                process.env.NODE_ENV !== "production"
                  ? "localTestingMode=true"
                  : ""
              }`,
            })}
            variant="epicode"
            className="mt-4 mb-4 mb-md-0  mt-md-0  font-weight-bold"
            disabled={value.length === 0}
          >
            {t("START")}
          </Button>
        </div>
      </Col>
    </Row>
  );
}

export default StartPage;