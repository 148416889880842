import { createContext, ReactNode, useEffect, useState } from "react";
import { QuestionType } from "types/questions";
import OptionsContainer from "./option/OptionsContainer";
import Option from "./option/Option";
import Text from "./text/Text";
import Topics from "./topics/Topics";
import Info from "./info/Info";
import Image from "./image/Image";

export type TQuestionProps = {
  children: ReactNode;
  type: QuestionType;
  onAnswerChange?: (answers: string[]) => void;
};

export const defaultQuestionValues = {
  providedAnswer: [],
  type: "SINGLE_CHOICE" as QuestionType,
  selectOption: () => {},
  isSelected: () => false,
};

export type TQuestionContext = {
  providedAnswer: string[];
  isSelected: (option: string) => boolean;
  selectOption: (option: string) => void;
  type: QuestionType;
};
export const QuestionContext = createContext<TQuestionContext>(
  defaultQuestionValues
);
export default function Question({
  children,
  type,
  onAnswerChange,
}: TQuestionProps) {
  const [providedAnswer, setProvidedAnswer] = useState<string[]>([]);

  const isSelected = (id: string) => providedAnswer.includes(id);

  const selectOption = (id: string) => {
    const exists = isSelected(id);
    setProvidedAnswer(
      exists
        ? providedAnswer.filter((answer) => answer !== id)
        : [...(type === "MULTIPLE_CHOICE" ? providedAnswer : []), id]
    );
  };

  useEffect(() => {
    onAnswerChange && onAnswerChange(providedAnswer);
  }, [onAnswerChange, providedAnswer]);

  const value = { providedAnswer, selectOption, isSelected, type };

  return (
    <QuestionContext.Provider value={value}>
      <div className="d-flex flex-column justify-content-center justify-content-center h-100">
        {children}
      </div>
    </QuestionContext.Provider>
  );
}

Question.OptionsContainer = OptionsContainer;
Question.Option = Option;
Question.Text = Text;
Question.Topics = Topics;
Question.Info = Info;
Question.Image = Image;
