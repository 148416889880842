import React from "react";
import { useContext } from "react";
import { Alert } from "react-bootstrap";
import { HiOutlineLightBulb } from "react-icons/hi";
import { QuestionContext, TQuestionContext } from "..";

export default function Info() {
  const { type } =
    useContext<TQuestionContext>(QuestionContext);
  return (
    <>
      {type === "MULTIPLE_CHOICE" && (
        <div className="mt-2">
          <div className="d-flex align-items-center">
            <HiOutlineLightBulb />
            <span className="p-2" style={{fontSize:16}}>
              This question is multiple choice. You can select multiple options.
            </span>
          </div>
        </div>
      )}
    </>
  );
}
