import React, { useEffect, useState } from "react";
 
export default function useFocus({ onTimeOut,onBlur }: { onTimeOut: () => void,onBlur: () => void }) {
  const [focused, setFocused] = useState(true);
  const [time, setTime] = useState(5);
  const focus = () => {
    setTime(5);
    setFocused(true);
  };
  const blur = () => {
    setFocused(false);
    onBlur()
  };
  useEffect(() => {
    window.addEventListener("focus", focus);
    window.addEventListener("blur", blur);
    return () => {
      window.removeEventListener("focus", focus);
      window.removeEventListener("blur", blur);
    };
  }, []);

  React.useEffect(() => {
    if (!focused && time !== 0) {
      const interval = setInterval(() => {
        setTime(time - 1);

        if (time === 1) {
          clearInterval(interval);
          onTimeOut();
        }
      }, 1000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, focused]);
  
  return {time,focused,setFocused}
 
}
