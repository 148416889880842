import backend from "clients/backend";
import InputField from "components/@common/input/InputField";
import Loader from "components/loader/Loader";
import Reveal from "components/Reveal";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import StarRatingComponent from "react-star-rating-component";
import { useImmer } from "use-immer";
//@ts-ignore
import NPSInput from "react-nps-input";
import { Rating } from "components/Rating";
import usePostHog from "../hooks/usePostHog";

type TNpsForm = { text?: string; score: null | number };

type TNpsState = TNpsForm[];

function Nps() {
  usePostHog();
  const [forms, setForms] = useState([
    {
      title: "HOW_IT_IS_GOING",
      subtitle: "WILL_YOU_RECOMMEND",
      hasOpenFeedback: true,
    },
    { title: "ABOUT_TEACHERS", subtitle: "HOW_DO_YOU_RATE_TEACHERS" },
  ]);

  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [nps, setNPS] = useImmer<TNpsState>([
    {
      text: "",
      score: 0,
    },
    { score: 0 },
  ]);
  const navigate = useNavigate();
  const checkIfThereIsTA = async () => {
    try {
      const { data } = await backend.get("/assignments/exams/nps/check-ta");
      console.log(data);
      if (data.hasTa) {
        setForms((prev) => [
          ...prev,
          {
            title: "ABOUT_TA",
            subtitle: "HOW_DO_YOU_RATE_TEACHERS",
          },
        ]);
        setNPS((draft) => {
          draft.push({ score: 0 });
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    checkIfThereIsTA();
  }, []);

  const submit = async () => {
    try {
      setLoading(true);
      await backend.post(
        "/assignments/exams/nps",
        {
          score: nps[0].score,
          text: nps[0].text,
          teacherScore: nps[1].score,
          ...(nps[2] && { taScore: nps[2].score }),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setLoading(false);
      navigate("/welcome");
    } catch (error) {
      console.log(error);
      navigate("/error");
    }
  };

  const goNext = () => {
    if (index !== forms.length - 1) {
      setIndex((prev: number) => prev + 1);
    } else {
      submit();
    }
  };
  const blink = (e: any) => {
    const target = e.currentTarget;
    console.log(e.target);
    e.currentTarget.classList.add("blink-1");
    setTimeout(() => {
      target.classList.remove("blink-1");
    }, 700);
  };
  const { t } = useTranslation();
  console.log(nps);

  return !loading ? (
    <Reveal index={index}>
      <div className="d-flex jsutify-content-center flex-column align-items-center text-center">
        <h2 className="mb-2 heading-2 text-bold">{t(forms[index].title)}</h2>
        <h3 className="mb-4 heading-3">{t(forms[index].subtitle)}</h3>
        <div className={"star-container"} onClick={blink}>
          <Rating
            transition
            size={50}
            stars={10}
            onClick={(i) => {
              setNPS((draft) => {
                draft[index].score = i;
              });
            }}
            ratingValue={nps[index].score ? (nps[index].score as number) : null}
          />
        </div>
        {typeof nps[index].text === "string" && (
          <>
            <h3 className="heading-3 mt-4">{t("LEAVE_AN_OPEN_FEEDBACK")}</h3>
            <Row className=" w-100 d-flex jsutify-content-center px-lg-5">
              <Col className="col-12 px-lg-5">
                <InputField
                  fullWidth
                  value={nps[index].text}
                  setValue={(value) =>
                    setNPS((draft) => {
                      draft[index].text = value;
                    })
                  }
                  placeholder={t("WRITE_COMMENT_HERE")}
                  error={false}
                  type="textarea  "
                  helperText={``}
                />
                <p
                  style={{
                    textAlign: "right",
                  }}
                >
                  <small>
                    {(nps[index].text || "")?.length > 100
                      ? "♥️"
                      : `${(nps[index].text || "")?.length}/100`}
                  </small>
                </p>
              </Col>
            </Row>
          </>
        )}

        <div className="mt-5  ">
          <Button
            disabled={
              (index === 0 && (nps[index].text || "")?.length < 1) ||
              nps[index].score === 0
            }
            variant="epicode"
            className="mt-4 mb-4 mb-md-0  mt-md-0  font-weight-bold"
            onClick={goNext}
          >
            {t("PROCEED")}
          </Button>
        </div>
      </div>
    </Reveal>
  ) : (
    <Loader />
  );
}

export default Nps;
