import AssignmentGuard from "guards/AssignmentGuard";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import routes from "./routes";
import * as Sentry from "@sentry/react";
import { useRecoilState } from "recoil";
import sentryStore from "store/sentry.store";
import MainLayout from "layout/main/MainLayout";
import { Fragment } from "react";
import ErrorPage from "pages/ErrorPage";
export default function AppRouter() {
const [, setSentryEvent] = useRecoilState(sentryStore);
  return (
    <Sentry.ErrorBoundary fallback={({ eventId }) => {
        setSentryEvent({ eventId });
        return <ErrorPage/>
   }}>
      <QueryParamProvider>
        <BrowserRouter>
          <Routes>
            {routes.map((routeProps) => {
              const Element = routeProps.element;
              const Layout = routeProps.layout ? MainLayout : Fragment;
              if (routeProps.isExamPage) {
                return (
                  <Route
                    {...routeProps}
                    key={routeProps.path}
                    element={
                      <AssignmentGuard>
                        <Layout navbar={routeProps.navbar}>
                          <Element />
                        </Layout>
                      </AssignmentGuard>
                    }
                  />
                );
              } else {
                return (
                  <Route
                    {...routeProps}
                    key={routeProps.path}
                    element={
                      <Layout navbar={routeProps.navbar}>
                        <Element />
                      </Layout>
                    }
                  />
                );
              }
            })}
            <Route path="*" element={<Navigate to="/?error=NOT_FOUND" />} />
          </Routes>
        </BrowserRouter>
      </QueryParamProvider>
  </Sentry.ErrorBoundary>
  );
}
