import { RecoilRoot } from "recoil";
import AppRouter from "router/AppRouter";

export default function App() {

  return (
    <RecoilRoot>
      <AppRouter />
    </RecoilRoot>
  );
}
