import React, { useEffect, useRef, useState } from "react";
import "./InputField.css";

type TInputFieldProps = {
  label?: string;
  fullWidth?: true;
  placeholder?: string;
  value: string;
  setValue: (value: string) => void;
  error: boolean;
  helperText: string;
  type?: string;
};

function InputField({
  label,
  fullWidth,
  placeholder,
  value,
  setValue,
  error,
  helperText,
  type
}: TInputFieldProps) {
  const [focused, setFocused] = useState(false);

  return (
    <div className={`root ${fullWidth ? "w-100" : "w-50"} `}>
      {label && (
        <label
          className={`input-label ${focused ? "input-label-focused " : ""} ${
            value.length > 0 ? "input-label-filled" : ""
          } `}
          htmlFor="email"
        >
          {label}
        </label>
      )}
      <div
        className={`input-root ${focused ? "input-root-focused" : ""} ${
          error && value.length > 0 ? "input-root-error" : ""
        } ${value.length > 0 ? "input-root-filled" : ""} `}
      >
        <input
          id="email"
          value={value}
          placeholder={placeholder}
          onChange={(e) => setValue(e.target.value)}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          type={type}
          
        
        />
      </div>
      {error && value.length > 0 && (
        <span className="mt-2" style={{ color: "red", fontSize: "0.8rem" }}>
          {helperText}
        </span>
      )}
    </div>
  );
}

InputField.defaultProps = {
  value: "",
  setValue: () => {},
};
export default InputField;
