import React, {
  Children,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { Col, Row } from "react-bootstrap";

export default function OptionsContainer({
  children,
  total,
}: {
  children: ReactNode;
  total: number;
}) {
  const [colSize, setColSize] = useState({ left: 12, right: 12 });
  const [imgElement, setImageElement] = useState<ReactElement | null>(null);
  useEffect(() => {
    Children.forEach(children, (element) => {
      if (!React.isValidElement(element)) return;
      if (
        element.props.hasOwnProperty("src") &&
        element.props.src !== undefined
      ) {
        setColSize({ left: 4, right: 8 });
        setImageElement(element);
      }
    });
  }, [children]);
  return (
    <Row className="mt-4 d-flex align-items-center">
      {imgElement && (
        <Col className="col-12" lg={colSize.left}>
          {imgElement}
        </Col>
      )}
      <Col className="col-12" lg={colSize.right}>
        <Row>
          {Children.map<ReactNode, ReactNode>(children, (el) => {
            if (React.isValidElement(el) && !el.props.hasOwnProperty("src"))
              return (
                <Col
                  className={`mb-3 col-12 col-lg-${
                    total <= 2 && imgElement ? "12" : "6"
                  } d-flex justify-content-center`}
                >
                  {el}
                </Col>
              );
          })}
        </Row>
      </Col>
    </Row>
  );
}
