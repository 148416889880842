import { Trans, useTranslation } from "react-i18next";

type TScoreProps = {
  title?: string;
  value: number | string;
  totalQuestions: number;
  qstRatio: number;
  align?: string;
};
const Score = ({
  title,
  value,
  totalQuestions,
  qstRatio,
  align,
}: TScoreProps) => {
  const { t } = useTranslation();
  return (
    <div className={`d-flex flex-column ${align && `align-items-${align}`}`}>
      {title && <h2 className="heading-2">{t(title)}</h2>}
      <h2 className="heading-2 text-bold">{value}%</h2>
      <span
        style={{
          fontSize: "20",
          lineHeight: "28px",
          fontWeight: 300,
        }}
      >
        <Trans
          i18nKey={"QUESTIONS_RATIO"}
          total={totalQuestions}
          correct={qstRatio}
        >
          {{ correct: qstRatio }}/{{ total: totalQuestions }} questions
        </Trans>
      </span>
    </div>
  );
};

export default Score;
