import MainLayout from "layout/main/MainLayout";
import ErrorPage from "pages/ErrorPage";
import ExamPage from "pages/ExamPage";
import FinalScore from "pages/FinalScore";
import Nps from "pages/NPS";
import Certificate from "pages/certificate/Certificate";
import StartPage from "pages/StartPage";
import ThanksPage from "pages/ThanksPage";
import WelcomePage from "pages/WelcomePage";
import CodingChallenge from "pages/CodingChallenge";
import StartPageMagnet from "pages/StartPageMagnet";
import FinalScoreMagnet from "pages/ThanksMagnet";
import ThanksPageMagnet from "pages/ThanksMagnet";

const routes = [
  {
    path: "/init",
    element: StartPage,
    layout: MainLayout,
    navbar: true,
    isExamPage:true
  },
  {
    path: "/start",
    element: StartPageMagnet,
    layout: MainLayout,
    navbar: true,
    isExamPage:true
  },
  {
    path: "/credentials/:id",
    element: Certificate,
    navbar: false,
  },
  {
    path: "/",
    element: StartPage,
    layout: MainLayout,
    navbar: true,
    isExamPage:true
  },
  {
    path: "/error",
    element: ErrorPage,
    layout: MainLayout,
    navbar: true,
  },
  // --- Exam Page ---
  {
    path: "/exam",
    element: ExamPage,
    layout: MainLayout,
    isExamPage: true,
    navbar: true,
  },
  {
    path: "/code",
    element: CodingChallenge,
    layout: MainLayout,
    isExamPage: true,
    navbar: true,
  },
  {
    path: "/welcome",
    element: WelcomePage,
    layout: MainLayout,
    isExamPage: true,
    navbar: true,
  },
  {
    path: "/result",
    element: FinalScore,
    layout: MainLayout,
    isExamPage: true,
    navbar: true,
  },
  {
    path: "/final-result",
    element: ThanksPageMagnet,
    layout: MainLayout,
    isExamPage: true,
    navbar: true,
  },
  {
    path: "/thanks",
    element: ThanksPage,
    layout: MainLayout,
    isExamPage: true,
    navbar: true,
  },
  {
    path: "/nps",
    element: Nps,
    layout: MainLayout,
    isExamPage: true,
    navbar: true,
  },
];

export default routes;
