import backend from "clients/backend";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsCheckCircle } from "react-icons/bs";
import usePostHog from '../hooks/usePostHog';
export default function ThanksPage() {
  usePostHog()
  const [links,setLinks] = useState<{admission:string}|null>(null)
  const fetchLinks = async () => {
    try {
      const {data} = await backend.get("/manage/settings/links")
      setLinks(data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(()=>{
    fetchLinks()
  },[])
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center color-primary">
          <BsCheckCircle
            style={{
              fontSize: "1.8rem",
              marginRight: "1rem",
              marginBottom: 5,
            }}
          />

          <span className="font-size-base">{t("COMPLETED")}</span>
        </div>
        <h2 className=" heading-2 text-bold">{t("THANKS_FOR_TEST")}</h2>
        <span className="mt-2 d-block font-size-base line-height-1">
          {t("OUR_TEAM_WILL_GET_BACK") + " "}{" "}
        </span>
        <span className="mt-4 mb-4 mb-md-0  mt-md-0 font-weight-bold">
          {i18n.language === "en" && (
            <Button
              style={{ marginTop: 20 }}
              variant="epicode"
              as="a"
              href={links ? links.admission : "https://meetings-eu1.hubspot.com/epicode/ammissioni"}
            >
              Click here to book your call.
            </Button>
          )}
        </span>
      </div>
    </>
  );
}