import React from "react";
import { Button } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

export default function BlurWarning({
  time,
  idle,
  focused,
  setIdle,
}: {
  time: number;
  idle: boolean;
  focused: boolean;
  setIdle: () => void;
}) {
  const { t } = useTranslation();
  if (idle) {
    return (
      <div
        className="d-flex align-items-center justify-content-center flex-column text-center"
        style={{
          height: "100vh",
          position: "fixed",
          width: "100%",
          top: 0,
          left: 0,
          backdropFilter: "blur(20px)",
          transition: "0.2s",
        }}
      >
        <h2 className="heading-2 text-bold">{t("YOU_RE_GONE_TO_LONG")}👀</h2>
        <p className="mt-5">{t("CLICK_HERE_TO_RESUME_EXAM")}</p>
      </div>
    );
  } else {
    return (
      <div
        className="d-flex align-items-center justify-content-center flex-column text-center"
        style={{
          height: "100vh",
          position: "fixed",
          width: "100%",
          top: 0,
          left: 0,
          backdropFilter: "blur(20px)",
          transition: "0.2s",
        }}
      >
        <h2 className="heading-2 text-bold">{t("COME_BACK")}</h2>
        <p className="mt-5">
          <Trans i18nKey="INVALIDATE_IN" count={time}>
            We will invalidate your question in <strong> {time}</strong>{" "}
            seconds.
          </Trans>
        </p>
      </div>
    );
  }
}
