import { useTranslation } from "react-i18next";

export default function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const switchLanguage = () => {
    console.log(i18n.language);
    i18n.changeLanguage(i18n.language === "en" ? "it" : "en");
  };
  return (
    <span
      style={{ cursor: "pointer", marginLeft: "1rem" }}
      onClick={switchLanguage}
    >
      <img
        width={32}
        src={i18n.language === "en" ? "/uk.svg" : "/it.svg"}
        alt="flag"
      />
    </span>
  );
}
