import * as Sentry from "@sentry/react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import errorStore, { TErrorTypes } from "store/errors.store";
import sentryStore from "store/sentry.store";
import usePostHog from '../hooks/usePostHog';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const errors = {
  NOT_FOUND: { text: "EXAM_NOT_FOUND", icon: "🤷‍♂️" },

  INTERNAL_SERVER_ERROR: {
    text: "INTERNAL_SERVER_ERROR",
    icon: "🤦🏻‍♂️",
  },
  NOT_ENOUGH_QUESTIONS:{
    text: "NOT_ENOUGH_QUESTIONS",
    icon: "🔎",
  },
  UNVERIFIED_CANDIDATE: {
    text: "UNVERIFIED_CANDIDATE",
    icon: "🕵🏻",
  },
  UNAUTHORIZED: { text: "UNAUTHORIZED", icon: "🙅‍♀️" },

  CERTIFICATE_NOT_FOUND: {
    text: "CERTIFICATE_NOT_FOUND",
    icon: "🤷‍♂️",
  },
  ASSIGNMENT_TIMED_OUT: {
    text: "ASSIGNMENT_TIMED_OUT",
    icon: "⌛️",
  },
  ASSIGNMENT_NOT_STARTED: {
    text: "ASSIGNMENT_NOT_STARTED",
    icon: "🤷‍♂️",
  },
};

const getError = (msg: TErrorTypes) =>
  errors[msg] ? errors[msg] : errors["INTERNAL_SERVER_ERROR"];

export default function ErrorPage() {
  usePostHog();
  const navigate = useNavigate()
  const [error] = useRecoilState(errorStore);
  const { t,i18n } = useTranslation();
  const [sentryEvent] = useRecoilState(sentryStore);
  
  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-center color-primary">
        <span className="heading-3 ">{getError(error).icon} Oops ...</span>
       
      </div>
      <h4 className=" heading-2 text-bold">{t(getError(error).text)}</h4>
      {error === "UNVERIFIED_CANDIDATE" &&  (
        <span className="mt-2 d-block font-size-base line-height-1">
          {t("PLEASE_REACH_OUT_TO")}
          <a href="mailto:info@epicode.com">info@epicode.com</a>
        </span>
      )}
      {sentryEvent.eventId && (
        <Button
          onClick={() => {
            Sentry.showReportDialog({
              title: t("SENTRY_REPORT_TITLE"),
              subtitle: t("SENTRY_REPORT_SUBTITLE"),
              subtitle2: t("SENTRY_REPORT_SUBTITLE2"),
              labelSubmit: t("SENTRY_REPORT_SUBMIT"),
              labelClose: t("SENTRY_REPORT_CLOSE"),
              eventId: sentryEvent.eventId!!, lang: i18n.language
            })
           
          }}
          variant="epicode"
          className="mt-4 w-50"
        >
          {t("REPORT_ISSUE")}
        </Button>
      )}
    </div>
  );
}
