import backend from "clients/backend";
import Checkbox from "components/checkbox";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import assignmentStore from "store/assignment.store";
import * as Sentry from "@sentry/react";
import sentryStore from "store/sentry.store";
import usePostHog from '../hooks/usePostHog';
import useIframeCheck from "../hooks/isIframe";
export default function WelcomePage() {
  usePostHog()
  const [, setSentrEvent] = useRecoilState(sentryStore);
  const [checked, setChecked] = useState(false);
  const [assignment] = useRecoilState(assignmentStore);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const {isIframe} = useIframeCheck()
  const startExam = async () => {
    if (isIframe){
     // open current page in new tab
      window.open(window.location.href, "_blank");
    }
    else{
      try {
        await backend.get("/assignments/start", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        navigate("/exam");
      } catch (error) {
        const event = await Sentry.eventFromException(error);
        const eventId = Sentry.captureEvent(event);
        setSentrEvent({ eventId });
        navigate("/error");
      }
    }
  };

  return (
    <>
      <>
        <h1 data-test="welcome-to-exam" className="heading mt-5 mt-lg-0">
          {t("WELCOME_TO")} <span> {t("YOUR_EXAM")} </span>
        </h1>
        <h3 className="heading-3 text-bold color-primary mb-2 mt-5">
          {t("INSTRUCTIONS")}{" "}
        </h3>
        {assignment.track === "Data Engineer" && i18n.language === "it" ? (
          <p className="width-70 font-size-base ">
            Il test di ammissione EPICODE si compone di 2 prove: un test di
            logica e un test di personalità. Una volta completati entrambi,
            avrai portato a termine con successo il processo di ammissione, e ti
            contatteremo entro 24 ore con l'esito della tua candidatura.
          </p>
        ) : (
          <p className="width-50 font-size-base "> {t("INTRO")} </p>
        )}

        <ul className="mt-2 font-size-base line-height-1">
          <li>{t("RULE-I")}</li>
          <li>{t("RULE-II")}</li>
           <li> {t('SCREEN_RECORDING')}</li>
          <li>{t("EXAM_WILL_START_ON_CLICK")}</li>
        </ul>

        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-5 pt-md-5">
          <Checkbox
            checked={checked}
            setChecked={setChecked}
            label={t("AGREE_TO_TERMS")}
          />
          <Button
            variant="epicode"
            data-test-id="welcome-proceed-button"
            className="mt-4 mb-4 mb-md-0  mt-md-0  font-weight-bold"
            disabled={!checked}
            onClick={startExam}
          >
            {t("PROCEED")}
          </Button>
        </div>
      </>
    </>
  );
}


