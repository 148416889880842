import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import useIframeCheck from "hooks/isIframe";
import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import Score from "./Score";

ChartJS.register(ArcElement, Tooltip);
const ShadowPlugin = {
  id: "doughnut",
  beforeDraw: (chart: any, args: any, options: any) => {
    const { ctx } = chart;
    let _fill = ctx.fill;
    ctx.fill = function () {
      ctx.save();
      ctx.shadowColor = "rgba(0, 0, 0, 0.6)";
      ctx.shadowBlur = 10;
      ctx.shadowOffsetX = 6;
      ctx.shadowOffsetY = 6;
      _fill.apply(this, arguments);
      ctx.restore();
    };
  },
};

function Chart({ result }: any) {
  const [data, setData] = useState<any>({
    labels: ["WRONG", "CORRECT"],
    datasets: [
      {
        label: "SCORE",
        data: [33.3, 66.7],
        backgroundColor: ["rgba(157, 29, 143, 1)", "rgba(0, 255, 255, 1)"],
        borderColor: ["rgba(157, 29, 143, 1)", "rgba(0, 255, 255, 1)"],
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    setData({
      ...data,
      datasets: [
        {
          ...data.datasets[0],
          data: [100 - result.score, result.score],
        },
      ],
    });
  }, [result]);

  return (
    <div className="d-flex flex-column flex-lg-row justify-content-around w-100 align-items-center">
      <div className="d-none d-lg-block">
        <Score
          title={"CORRECT"}
          value={result.score}
          totalQuestions={result.total}
          qstRatio={result.correct}
          align="start"
        />
      </div>

      <div style={{ position: "relative", width: 470, maxWidth: "100%" }}>
        <Doughnut
          data={data}
          options={{
            cutout: "70%",
            layout: {
              padding: 5,
            },
          }}
          plugins={[ShadowPlugin]}
        />

        <div
          className="d-block d-lg-none"
          style={{
            top: "50%",
            left: "50%",
            position: "absolute",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Score
            title={"SCORE"}
            value={result.score}
            totalQuestions={result.total}
            qstRatio={result.correct}
            align="center"
          />
        </div>
      </div>
      <div className="d-none d-lg-block">
        <Score
          title={"WRONG"}
          value={(100 - result.score).toFixed(2)}
          totalQuestions={result.total}
          qstRatio={result.wrong}
          align="end"
        />
      </div>
    </div>
  );
}

export default Chart;
