import backend from "clients/backend";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";
import "./Certificate.css";


function Certificate() {
  const [data, setData] = useState(null);
  const { id } = useParams();
  // alert(window.innerHeight + " " + window.innerWidth);
  const fetchData = async () => {
    try {
      const { data } = await backend.get("/credentials/" + id);
      // const res = await fetch(data.links.download.svg);
      // console.log(res);
      setData(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  //
  return data ? (
  
    <>

      <Container
        className="d-flex justify-content-center certificate-nav "
        fluid
      >
        <div className="d-flex align-items-center h-100 certificate-container">
          <a rel="noreferrer" target={"_blank"} href="https://epicode.com/">
            <img
              alt="slogo"
              src={"/logo.svg"}
              width="100px"
            />
          </a>
        </div>
      </Container>
      <Container
        fluid
        className="certificate-bg-container  d-flex justify-content-center align-items-center "
      >
        <div className="certificate-container " style={{ marginTop: "80px" }}>
          <img
            alt="certificate"
            src={data.links.download.svg}
            style={{
              width: "100%",
              height: "100%",
              filter: " drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.5))",
            }}
          />
        </div>
      </Container>
      <Container
        fluid
        className="d-flex justify-content-center  mt-5  mt-lg-3 mb-3 pt-2 pb-5"
      >
        <div className="certificate-container flex-column flex-lg-row d-flex justify-content-between ">
          <div className="d-flex flex-column pt-3">
            <span className="text-bold">EPICODE COURSE SPECIFICATIONS</span>
            <span className="mt-3">Completion date</span>
            <span className="text-bold">{data.releaseDate}</span>
            <span className="mt-3">Awarded to</span>
            <span className="text-bold">{data.student.name}</span>
            <span className="mt-3">Description</span>
            <span className="text-bold certificate-description">
              {data.preset.description}
            </span>
          </div>
          <div className="share-container pt-4 pb-4 mt-5 mt-lg-0  ps-4 pe-5">
            <span className="text-bold">SHARE</span>
            <div className="d-flex mt-2 ">
              <TwitterShareButton className="me-2" url={data.links.url}>
                <TwitterIcon round size={35} />
              </TwitterShareButton>
              <LinkedinShareButton className="me-2" url={data.links.url}>
                <LinkedinIcon round size={35} />
              </LinkedinShareButton>
              <FacebookShareButton className="me-2" url={data.links.url}>
                <FacebookIcon round size={35} />
              </FacebookShareButton>
              <WhatsappShareButton className="me-2" url={data.links.url}>
                <WhatsappIcon round size={35} />
              </WhatsappShareButton>
            </div>
          </div>
        </div>
      </Container>
      <Container
        fluid
        className="d-flex justify-content-center footer"
        style={{ backgroundColor: "#0b113b" }}
      >
        <div className="mt-5 certificate-container">
          <img
            alt="logo"
            src={"/logo.svg"}
            width="100px"

          />
          <div className="d-flex flex-column flex-lg-row justify-content-between align-items-start align-items-lg-center my-5 pb-5">
            <div>
              <p className="font-size-6 text-bold" style={{ color: "white" }}>
                FOLLOW US
              </p>
              <div className="icons-container">
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href="https://discord.gg/3VfhxZhJ"
                >
                  <img
                    alt="discord"
                    className="me-2"
                    src="/icons/discord.png"
                  />
                </a>
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href="https://www.linkedin.com/school/epicodeglobal/"
                >
                  <img
                    alt="linkedin"
                    className="me-2"
                    src="/icons/linkedin.png"
                  />
                </a>
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href="https://www.instagram.com/epicode.global/"
                >
                  <img
                    alt="instagram"
                    className="me-2"
                    src="/icons/instagram.png"
                  />
                </a>
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href="https://www.youtube.com/c/EPICODEGlobal"
                >
                  <img
                    alt="youtube"
                    className="me-2"
                    src="/icons/youtube.png"
                  />
                </a>
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href="https://www.facebook.com/epicodeglobal"
                >
                  <img
                    alt="facebook"
                    className="me-2"
                    src="/icons/facebook.png"
                  />
                </a>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-end align-items-start align-items-lg-end mt-5 mt-lg-0">
              <span>
                © Copywright 2020 – Epic Education srl – Via Baccio Baldini 12,
                00146 Roma
              </span>
              <span>
                P.IVA: 15878411006 All rights reserved –{" "}
                <span>Privacy Policy </span>
              </span>
              <span>Epicode School e Strive School</span>
            </div>
          </div>
        </div>
      </Container>
    </>
  ) : (
    <></>
  );
}

export default Certificate;
