import axios, { AxiosError } from "axios";
import * as Sentry from "@sentry/react";

const backend = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});

// Add a response interceptor
backend.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: AxiosError) {
    Sentry.captureException({
      type: "AXIOS_ERROR",
      message: error.message,
      status: error.response?.status,
      stack: error.stack,
      data: error.response?.data,
      config: error.response?.config,
    });
    backend.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    return Promise.reject(error);
  }
);

export default backend;
