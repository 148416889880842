/* eslint-disable jsx-a11y/img-redundant-alt */

import React from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
export default function QuestionImage({ src }: { src?: string }) {
  return (
    <>
      {src && (
        <div className="mb-3 h-100" title="Click on image to zoom">
          <Zoom wrapStyle={{ height: "100%", width: "100%" }}>
            <img
              src={src}
              alt="question-img"
              height="100%"
              width="100%"
              style={{ objectFit: "cover" }}
            />
          </Zoom>
          <p
            className="mt-2"
            style={{
            fontSize: 12,
            
          }}>You can click on image to zoom</p>
        </div>
      )}
    </>
  );
}
