import LanguageSwitcher from "components/LanguageSwitcher";
import useIframeCheck from "hooks/isIframe";
import { BiFullscreen } from "react-icons/bi";
import { ButtonGroup, Navbar } from "react-bootstrap";

export default function EpicodeNavbar() {
  const { isIframe } = useIframeCheck();

  const token = localStorage.getItem("token");

  return (
    <Navbar expand="lg" className="d-flex justify-content-between w-100">
      <Navbar.Brand>
        <EpicodeLogo isIframe={isIframe} />
      </Navbar.Brand>
      <ButtonGroup
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LanguageSwitcher />
        {token && isIframe && (
          <a href={`?token=${token}`} target="_blank">
            <BiFullscreen
              style={{
                fontSize: "1.4rem",
                marginTop: "5px",
                marginLeft: "15px",
                cursor: "pointer",
                color: "white",
              }}
            />
          </a>
        )}
      </ButtonGroup>
    </Navbar>
  );
}

export function EpicodeLogo(
  { isIframe }: { isIframe?: boolean } = { isIframe: false }
) {
  return (
    <img
      className="logo"
      alt="epicode-logo"
      src="/logo.png"
      style={{
        visibility: isIframe ? "hidden" : "visible",
      }}
    />
  );
}
