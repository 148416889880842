import Exam from "components/@exam";
import usePostHog from '../hooks/usePostHog';

export default function ExamPage() {
  usePostHog()
  return (
    <>
      <Exam />
    </>
  );
}
