import useLocalStorage from "hooks/useLocalStorage";
import React, { useState } from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import "./timer.css";

export type TTimerProps = {
  time: number;
  autoStart?: boolean;
  onRunning?: (time: number) => void;
  onEnd?: () => void;
  onStart?: () => void;
  align?: "left" | "right";
  fixed?: boolean;
  paused: boolean;
};

const getPercentage = (time: number, total: number) => {
  return (time * 100) / total;
};

export default function Timer(props: TTimerProps) {
  const [time, setTime] = useState(props.time);
  const { t } = useTranslation();
  React.useEffect(() => {
    if (props.autoStart && time !== 0 && !props.paused) {
      props.onStart && props.onStart();
      const interval = setInterval(() => {
        if (!props.paused) {
          setTime(time - 1);
        }
        props.onRunning && props.onRunning(time);
        if (time === 1) {
          props.onEnd && props.onEnd();
          clearInterval(interval);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  return (
    <div style={{ width: 150, height: 150, margin: "5px" }}>
      <CircularProgressbarWithChildren
        value={getPercentage(time, props.time)}
        counterClockwise={true}
        strokeWidth={10}
        backgroundPadding={8}
        background
        styles={{
          background: {
            fill: "transparent",
          },
          path: {
            stroke: "#9d1d8f",
          },
          trail: {
            filter: "drop-shadow( 3px 3px 2px  rgba(0, 0, 0, .4))",
          },
        }}
      >
        <div className="d-flex flex-column  align-items-center time-wrapper">
          <span>{t("SECONDS")}</span>
          <span>{time.toString()}</span>
          <span>{t("REMAINING")}</span>
        </div>
      </CircularProgressbarWithChildren>
    </div>
  );
}

Timer.defaultProps = {
  time: 30,
  autoStart: false,
  paused: false,
};
