import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import backend from "clients/backend";
import Loader from "components/loader/Loader";
import useLocalStorage from "hooks/useLocalStorage";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import assignmentStore from "store/assignment.store";
import errorStore, { TErrorTypes } from "store/errors.store";
import sentryStore from "store/sentry.store";
import { StringParam, useQueryParam } from "use-query-params";

export type TAssignmentGuardProps = {
  children: React.ReactNode;
};

export default function AssignmentGuard({ children }: TAssignmentGuardProps) {
  const { i18n } = useTranslation();
  const [tokenInLocalStorage] = useLocalStorage("token", null);
  const [tokenInQuery] = useQueryParam("token", StringParam);
  const [errorInQuery] = useQueryParam("error", StringParam);
  const [langInQuery] = useQueryParam("lang", StringParam);
  const [, setError] = useRecoilState(errorStore);
  const [, setSentryEvent] = useRecoilState(sentryStore);
  const [, setAssignment] = useRecoilState(assignmentStore);

  const token = tokenInQuery || tokenInLocalStorage || null;
  const lang = langInQuery ? langInQuery.toLocaleLowerCase() : null;
  const error = errorInQuery || "INTERNAL_SERVER_ERROR";

  const setInitialValues = (): Promise<string | null> =>
    new Promise((resolve) => {
      if (lang) {
        i18n.changeLanguage(lang);
      }
      if (token) {
        localStorage.setItem("token", token);
      } else {
        if (location.pathname !== "/init") {
          setError("UNAUTHORIZED");
          navigateIfNotAlreadyThere("/error");
        }
      }
      if (error) {
        setError(error as TErrorTypes);
        if (error === "ASSIGNMENT_TIMED_OUT") {
          navigateIfNotAlreadyThere("/error");
        } else if (error === "NOT_ENOUGH_QUESTIONS") {
          navigateIfNotAlreadyThere("/error");
        } else if (error === "ASSIGNMENT_NOT_STARTED") {
          navigateIfNotAlreadyThere("/error");
        } else {
          resolve(token);
        }
      }
    });

  const navigate = useNavigate();
  const location = useLocation();
  const navigateIfNotAlreadyThere = (path: string) => {
    if (location.pathname !== path) {
      navigate(path);
    }
  };
  const [loading, setLoading] = useState(true);

  const successResponseHandler = (assignment: any) => {
    setAssignment(assignment);
    switch (assignment.status) {
      case "NPS":
        return navigateIfNotAlreadyThere("/nps");
      case "CREATED":
        return navigateIfNotAlreadyThere("/welcome");
      case "STARTED":
        return navigateIfNotAlreadyThere("/exam");
      case "IN_PROGRESS":
        return navigateIfNotAlreadyThere("/exam");
      case "COMPLETED":
        if (assignment.type === "ADMISSION") {
          // if its data redirect to
          if (assignment.track?.startsWith("Data")) {
            window.location.replace(
              "https://app.talentoday.com/epic-education-srl/280/users/new"
            );
          } else {
            if (assignment.admission_method === "magnet") {
              navigateIfNotAlreadyThere("/final-result");
            } else {
              navigateIfNotAlreadyThere("/thanks");
            }
          }
        }
        else{
          navigateIfNotAlreadyThere("/result");
        }
        break;
      default:
        break;
    }
  };
  const errorResponseHandler = async (error: AxiosError) => {
    const Event = await Sentry.eventFromException(error, {
      captureContext: {
        extra: {
          ...error,
        },
      },
    });
    const eventId = Sentry.captureEvent(Event);
    setSentryEvent({
      eventId,
    });
    switch (error.response?.status) {
      case 401:
        if (errorInQuery !== "UNVERIFIED_CANDIDATE") {
          localStorage.clear();
          setError("UNAUTHORIZED");
          navigateIfNotAlreadyThere("/init");
        } else {
          setError("UNVERIFIED_CANDIDATE");
          navigateIfNotAlreadyThere("/error");
        }
        break;
      case 404:
        setError("NOT_FOUND");
        return navigateIfNotAlreadyThere("/error");
      case 400:
        setError(error.response?.data.message || "INTERNAL_SERVER_ERROR");
        return navigateIfNotAlreadyThere("/error");
      default:
        return navigateIfNotAlreadyThere("/error");
    }
  };

  const fetchAssignment = async (token: string | null) => {
    try {
      const { data } = await backend.get("/assignments", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return successResponseHandler(data);
    } catch (error) {
      return errorResponseHandler(error as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.pathname !== "/start") {
      setInitialValues().then(fetchAssignment);
    }
    else{
      setLoading(false);
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loader />;
  } else {
    return <>{children}</>;
  }
}
