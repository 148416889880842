import backend from "clients/backend";
import Progress from "components/@exam/progress/Progress";
import Question from "components/@exam/question";
import Timer from "components/@exam/timer/Timer";
import Loader from "components/loader/Loader";
import useFocus from "hooks/useFocus";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IQuestion } from "types/questions";
import BlurWarning from "./warning/BlurWarning";
import "./index.css";

function Exam() {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const [loading, setLoading] = useState(true);
  const [idle, setIdle] = useState(false);
  const [providedAnswer, setProvidedAnswer] = useState<string[]>([]);
  const [progress, setProgress] = useState<{ current: number; total: number }>({
    current: 0,
    total: 0,
  });
  const [question, setQuestion] = useState<IQuestion | null>(null);
  const onNext = async () => {
    try {
      setLoading(true);
      await backend.post(
        `/assignments/exams/answer`,
        { providedAnswer },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setLoading(false);
      fetchQuestion();
    } catch (error) {
      window.location.replace("/init");
    }  
  };

  const fetchQuestion = async () => {
    try {
      setLoading(true);
      setProvidedAnswer([]);
      setQuestion(null);
      const {
        data: { question, current, total },
      }: { data: { question: IQuestion; current: number; total: number } } =
        await backend.get(`/assignments/exams/question`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
      setQuestion(question);
      setProgress({ current, total });
      setLoading(false);
    } catch (error) {
      window.location.replace("/init");
    }
  };

  useEffect(() => {
    fetchQuestion();
  }, []);

  const { focused, time, setFocused } = useFocus({
    onTimeOut: () => {
      setProvidedAnswer([]);
      setIdle(true);
      onNext();
    },
    onBlur: () => {
      setIdle(false);
    },
  });

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        question && (
          <>
            <div
              className="w-100 d-flex  flex-row-reverse"
              style={{ visibility: focused ? "visible" : "hidden" }}
            >
              <Timer fixed autoStart onEnd={onNext} time={question.duration} />
            </div>
            {focused ? (
              <Question type={question.type} onAnswerChange={setProvidedAnswer}>
                <Question.Text
                  text={
                    (question.text as any)[lang]
                      ? (question.text as any)[lang]
                      : (question.text as any)["en"]
                  }
                />
                {/* <Question.Topics topics={question.topics} /> */}
                <Question.Info />
                <Question.OptionsContainer total={question.answers.length}>
                 {question.img &&  <Question.Image src={question.img} />}
                  {question.answers.map((option: any, index) => (
                    <Question.Option
                      totalOptions={question.answers.length}
                      id={option._id}
                      text={
                        (option.text as any)[
                          (option.text as any)[lang] ? lang : "en"
                        ]
                      }
                    />
                  ))}
                </Question.OptionsContainer>
                <Progress
                  total={progress.total}
                  current={progress.current + 1}
                  onNext={onNext}
                />
              </Question>
            ) : (
              <BlurWarning
                idle={idle}
                focused={focused}
                time={time}
                setIdle={() => {
                  setIdle(false);
                  setFocused(true);
                }}
              />
            )}
          </>
        )
      )}
    </>
  );
}

export default Exam;
