import React from "react";
import "./index.css";

type TCheckboxProps = {
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
};
function Checkbox({ checked, setChecked, label }: TCheckboxProps) {
  return (
    <div
      className="d-flex align-items-center"
      data-test-id="welcome-rule-accept-checkbox"
    >
      <div className="checkbox-wrapper">
        <input
          type="checkbox"
          id="custom-checkbox"
          checked={checked}
          onChange={() => setChecked(!checked)}
        />
        <label htmlFor="custom-checkbox"></label>
      </div>
      <div
        className="text-bold ml-4 font-size-6"
        style={{ cursor: "pointer", marginLeft: "1.5rem" }}
        onClick={() => setChecked(!checked)}
      >
        {label}
      </div>
    </div>
  );
}

export default Checkbox;
