import { useContext } from "react";
import { Button, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { HiOutlineArrowRight } from "react-icons/hi";
import { QuestionContext, TQuestionContext } from "../question";
export type TProgress = {
  current: number;
  total: number;
  onNext?: () => void;
};

export default function Progress({ current, total, onNext }: TProgress) {
  const { providedAnswer } = useContext<TQuestionContext>(QuestionContext);
  const { t } = useTranslation();
  return (
    <Container className="my-5">
      <div className="d-flex flex-column flex-md-row justify-content-between w-100 align-items-center">
        <div className="text-center">
          <h6 className="question-number">
            {t("QUESTION")} {current} <span>/ {total}</span>
          </h6>
        </div>

        <Button
          variant="epicode"
          className="mt-md-0 mt-2"
          onClick={() => onNext && onNext()}
          disabled={providedAnswer.length === 0}
        >
          {t("NEXT")}
          <span className="p-1">
            <HiOutlineArrowRight />
          </span>
        </Button>
      </div>
    </Container>
  );
}
