
import { atom } from "recoil";

export type TSentryEvent = {
  eventId:string|null
}

const sentryStore = atom<TSentryEvent>({
  key: "sentry",
  default: {
    eventId:null
  },
});

export default sentryStore;
