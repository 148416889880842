import backend from "clients/backend";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsCheckCircle } from "react-icons/bs";
import usePostHog from "../hooks/usePostHog";
import { useNavigate } from "react-router-dom";

export default function ThanksPageMagnet() {
  usePostHog();
  const [score, setScore] = useState<number>(0);
  const [links, setLinks] = useState<{ admission: string } | null>(null);
  const navigate = useNavigate()
  const fetchLinks = async () => {
    try {
      const { data } = await backend.get("/manage/settings/links");
      setLinks(data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchBenchmark = async () => {
    try {
      const { data } = await backend.get("/assignments");
      if(data.admission_method!=='magnet'){
        navigate('/thanks')
      }
      setScore(parseInt(data.score.toFixed(2)));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchLinks();
    fetchBenchmark();
  }, []);
  const { t, i18n } = useTranslation();
  return (
    <div style={{
      
    }}>
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center color-primary">
          <BsCheckCircle
            style={{
              fontSize: "1.8rem",
              marginRight: "1rem",
              marginBottom: 5,
            }}
          />

          <span className="font-size-base">{t("COMPLETED")}</span>
        </div>
        <h2
          style={{
            marginBottom: 20,
          }}
          className=" heading-2 text-bold"
        >
          {t("THANKS_FOR_TEST")}
        </h2>
        <span
          className="mt-2 d-block font-size-base line-height-1"
          style={{
            marginBottom: 20,
          }}
        >
          <span
            style={{
              fontSize: "1.5rem",
            }}
          >
            {t("YOUR_SCORE", { score })}
          </span>
        </span>
        <span className="mt-10 mb-4 mb-md-0  mt-md-0 font-weight-bold">
          {i18n.language === "en" ? (
            <Button
              style={{ marginTop: 20 }}
              variant="epicode"
              as="a"
              href={
                links
                  ? links.admission
                  : "https://meetings-eu1.hubspot.com/epicode/ammissioni"
              }
            >
              Click here to book your call.
            </Button>
          ) : (
            <>
              {score < 30 && (
                <span
                  style={{
                    marginTop: "3rem",
                  }}
                >
                  C'è ancora tanto da lavorare sulle tue capacità logico
                  deduttive, hai totalizzato un punteggio che non ti permette
                  l'ammissione diretta ad EPICODE. Però puoi studiare e
                  prepararti al meglio per essere pronto ad affrontare i nostri
                  percorsi didattici. Hai considerato la partecipazione ad uno
                  dei nostri 
                  <a
                    target="_blank"
                    href="https://epicode.com/it/tech-camp-il-mini-bootcamp-gratuito-epicode/"
                    style={{
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    Tech Camp
                  </a>
                  ? Se invece hai bisogno di maggiore flessibilità puoi provare
                  a completare il nostro corso in 
                  <a
                    target="_blank"
                    style={{
                      color: "white",
                      fontWeight: "bold",
                    }}
                    href="https://epicode.com/it/corso-di-programmazione-gratuito-html-css-flexbox/"
                  >
                    HTML e CSS
                  </a>
                  , è completamente gratuito e puoi seguirlo quando e dove vuoi!
                </span>
              )}
              {
                score >= 30 && score < 60 && (
                  <span>
                    Sei sulla buona strada! Le tue capacità logico deduttive hanno un ottimo potenziale di miglioramento e potrebbero essere sufficienti ad affrontare i nostri corsi: adesso che hai completato il test non ti resta che prenotare una consulenza di orientamento per scoprire tutto sui nostri programmi e sui posti ancora liberi in classe. Che cosa stai aspettando? 
                    
                    <br/> 
                    <br/> 
                    <a
                    style={{
                      color: "white",
                      fontWeight: "bold",
                    }}
                    href="https://app-eu1.hubspot.com/meetings/25552336"> 
                    Clicca qui per scegliere giorno e ora!
                    </a>
                  </span>
                )
              }
               {
                score >= 60 &&  (
                  <span>
                 Complimenti, ottimo lavoro: non solo hai superato il test, ma il tuo punteggio ti candida automaticamente ad una borsa di studio parziale per la partecipazione ai nostri corsi! Prenota ora una consulenza di orientamento e scopri tutto sulla nostra offerta didattica:
                    
                    <br/> 
                     <br/> 
                    <a 
                    style={{
                      color: "white",
                      fontWeight: "bold",
                    }}
                    href="https://app-eu1.hubspot.com/meetings/25552336"> 
                    Clicca qui per scegliere giorno e ora!
                    </a>
                  </span>
                )
              }
            </>
            
          )}
        </span>
      </div>
    </div>
  );
}
