import InputField from "components/@common/input/InputField";
import { useEffect, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { StringParam, useQueryParam } from "use-query-params";
import usePostHog from "../hooks/usePostHog";
import { stringify} from "qs"
function StartPageMagnet() {
  usePostHog();
  const [emailInQuery] = useQueryParam("email", StringParam);
  const [nameInQuery] = useQueryParam("name", StringParam);
  const [surnameInQuery] = useQueryParam("surname", StringParam);
  const [phoneInQuery] = useQueryParam("phone", StringParam);
  //utm_campaign, utm_medium, utm_source, utm_term, utm_content 
  const [utmCampaignInQuery] = useQueryParam("utm_campaign", StringParam);
  const [utmMediumInQuery] = useQueryParam("utm_medium", StringParam);
  const [utmSourceInQuery] = useQueryParam("utm_source", StringParam);
  const [utmTermInQuery] = useQueryParam("utm_term", StringParam);
  const analytics = {
    utm_campaign: utmCampaignInQuery,
    utm_medium: utmMediumInQuery,
    utm_source: utmSourceInQuery,
    utm_term: utmTermInQuery,
  }
  const [form, setForm] = useState({
    email: "",
    name: "",
    surname: "",
    phone: "",
  });
  const { t } = useTranslation();
  const isValidPhone = (phone: string) => {
    // allow phone numbers with international prefix
    return phone.match(/^\+(?:[0-9]●?){6,14}[0-9]$/);
  }
  const isValidEmail = (email: string) => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  }
  const isValidName = (name: string) => {
    // allow letters and multiple names
    return  name.match(/^[a-zA-Z]+(\s[a-zA-Z]+)*$/);
  }
  const isValidSurname = (surname: string) => {
    return  surname.match(/^[a-zA-Z]+(\s[a-zA-Z]+)*$/);
  }
  const isFormValid = () => {
    return isValidEmail(form.email) && isValidName(form.name) && isValidSurname(form.surname) && isValidPhone(form.phone);
  }
  const setFormFromQuery = () => {
    let formInQuery =  {
      email: "",
      name: "",
      surname: "",
      phone: "",
    }
    if (emailInQuery) {
      formInQuery = {...formInQuery, email: emailInQuery}
    }
    if (nameInQuery) {
      formInQuery = {...formInQuery, name: nameInQuery}
    }
    if (surnameInQuery) {
      formInQuery = {...formInQuery, surname: surnameInQuery}
    }
    if (phoneInQuery) {
      formInQuery = {...formInQuery, phone: `+${phoneInQuery}`}
    }
    setForm(formInQuery)
  }
  useEffect(() => {
    setFormFromQuery()
  }, [
    emailInQuery,
    nameInQuery,
    surnameInQuery,
    phoneInQuery,

  ]);
  return (
    <Row className="d-flex flex-column  ">
      <Col className="col-12 col-lg-6 d-flex flex-column">
        <h2>{t("REGISTER_FOR_ADMISSION_TEST")}</h2>
        <InputField
          fullWidth
          placeholder={t("TYPE_YOUR_NAME_HERE")}
          value={form.name}
          setValue={(value) => {
            setForm({ ...form, name: value });
          }}
          error={
            form.name.length > 0 && !isValidName(form.name)
          }
          helperText={"Invalid name"}
        />
        <InputField
          fullWidth
          placeholder={t("TYPE_YOUR_SURNAME_HERE")}
          value={form.surname}
          setValue={(value) => {
            setForm({ ...form, surname: value });
          }}
          error={
            form.surname.length > 0 && !isValidSurname(form.surname)
          }
          helperText={"Invalid surname"}
        />
        <InputField
          fullWidth
          placeholder={t("TYPE_YOUR_EMAIL_HERE")}
          value={form.email}
          setValue={(value) => {
            setForm({ ...form, email: value });
          }}
          error={
            form.email.length > 0 && !isValidEmail(form.email)
          }
          helperText={"Invalid email"}
        />{" "}
        <InputField
          fullWidth
          placeholder={`+39061278322`}
          value={form.phone}
          setValue={(value) => {
            setForm({ ...form, phone: value});
          }}
          error={
            form.phone.length > 0 && !isValidPhone(form.phone)
          }
          helperText={"Invalid phone"}
        />
        <div className="mt-5 align-self-center  align-self-lg-start ">
          <Button
            disabled={!isFormValid()}
            variant="epicode"
            as="a"
            href={
              `${process.env.REACT_APP_API_URL}/assignments/register-to-admission?${stringify({...form, ...analytics})}`
            }
            className="mt-4 mb-4 mb-md-0  mt-md-0  font-weight-bold"
          >
            {t("START")}
          </Button>
        </div>
      </Col>
    </Row>
  );
}

export default StartPageMagnet;
