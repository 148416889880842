import React from "react";
import { Badge } from "react-bootstrap";

export type TTopicsProps = {
  topics: string[];
};

export default function Topics({ topics }: TTopicsProps) {
  const content =
    topics.length > 1 ? `${topics[0]} +${topics.length - 1} more` : topics[0];
  return (
    <h5>
      <Badge pill bg="dark" className="mt-3 mb-3">
        {content}
      </Badge>
    </h5>
  );
}
