import * as Sentry from "@sentry/react";
import { useRef, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./index.css";

function Question({ question, index, details, setShow }: any) {
	const { i18n, t } = useTranslation();
	const lang = i18n.language;
	const growRef = useRef<null | HTMLDivElement>(null);
	const measuringDivRef = useRef<null | HTMLDivElement>(null);
	const textRef = useRef<null | HTMLDivElement>(null);
	const [collapse, setCollapse] = useState(true);

	const growDiv = () => {
		if (growRef.current!.clientHeight > 26) {
			setCollapse(true);
			growRef.current!.style.height = "26px";
			textRef.current!.style.overflow = "hidden";
			textRef.current!.style.whiteSpace = "nowrap";
		} else {
			setCollapse(false);
			textRef.current!.style.overflow = "visible";
			textRef.current!.style.whiteSpace = "normal";
			growRef.current!.style.height = `${
				measuringDivRef.current!.clientHeight
			}px`;
		}
	};
	const getLabel = () => {
		if (details) {
			if (details.correct) {
				return "CORRECT";
			} else {
				return "WRONG";
			}
		} else {
			return "NO_ANSWER";
		}
	};

	const openSentryDialog = async () => {
		const event = await Sentry.eventFromException(
			new Error("ISSUE_WITH_QUESTION"),
			{
				data: question,
				captureContext: {
					extra: question,
				},
			},
		);
		const eventId = Sentry.captureEvent(event);
		setShow?.(false);
		setTimeout(() => {
			Sentry.showReportDialog({
				eventId,
				title: t("REPORT_ISSUE"),
				subtitle: t("SENTRY_REPORT_QUESTION_SUBTITLE"),
				subtitle2: t("SENTRY_REPORT_SUBTITLE2"),
				labelSubmit: t("SENTRY_REPORT_SUBMIT"),
				labelClose: t("SENTRY_REPORT_CLOSE"),
			});
		}, 500);
	};

	return (
		<div className="w-100 question-wrapper px-4 py-4 mb-3">
			<Row>
				<Col className="col-1 col-lg-1  mt-3 mt-lg-0 d-flex justify-content-start  justify-content-lg-center align-items-start  order-3 order-lg-1">
					<span className="qst-number">{index + 1}.</span>
				</Col>
				<Col className="col-12 col-lg-7 mt-2 mt-lg-0 order-5 order-lg-2">
					<div className="grow " ref={growRef}>
						<div ref={measuringDivRef} className="measuringDiv">
							<div className="w-100 qst-text">
								<div ref={textRef} className="text-ellipsis">
									{question.text[lang]
										? question.text[lang]
										: question.text["en"]}
								</div>
							</div>

							{question.img && (
								<div className="mt-4 w-100">
									{" "}
									<Image className="qst_image" src={question.img} />
								</div>
							)}

							<Row className=" mt-4">
								<Col className="col-12 col-lg-6">
									{question.answers.map((answer: any) => {
										const isCorrect = answer.isCorrect;

										return (
											<div>
												<img
													src={`${
														isCorrect
															? "/icons/correct_answered.png"
															: "/icons/option.png"
													}`}
													className="me-2"
													style={{ height: "15px" }}
													alt='option-check'
												/>
												<span className="qst-text ">
													{answer.text[lang]
														? answer.text[lang]
														: answer.text["en"]}
												</span>
											</div>
										);
									})}
								</Col>
								<Col className="col-12 mt-4 mt-lg-0 col-lg-6">
									<span className="qst-text">{t("ANSWERED")}</span>

									{question.providedAnswer?.length > 0 &&
										question.providedAnswer.map((aId: any) => {
											const answer = question.answers.find(
												(a: any) => a._id === aId,
											);

											if (answer) {
												return (
													<div>
														<img
															alt=""
															src={`${
																answer?.hasOwnProperty("isCorrect") &&
																answer?.isCorrect
																	? "/icons/correct_answered.png"
																	: "/icons/wrong_answered.png"
															}`}
															className="me-2"
															style={{ height: "15px" }}
														/>
														<span className="qst-text">
															{answer.text[lang]
																? answer.text[lang]
																: answer.text["en"]}
														</span>
													</div>
												);
											} else {
												return <div>No answer found</div>;
											}
										})}
								</Col>
							</Row>
						</div>
					</div>
					<a href="#" className="qst-text " onClick={() => growDiv()}>
						{collapse ? t("SHOW_ALL") : t("SHOW_LESS")}
					</a>
				</Col>

				<Col className="col-11 col-lg-2 order-4 order-lg-3   mt-3 mt-lg-0">
					<div className=" d-flex flex-wrap justify-content-start align-items-center ps-2 ps-lg-0  align-items-lg-start  topics-container">
						{question.topics.slice(0, 1).map((t: string) => (
							<div className="qst-tag px-3 py-1 me-2  mb-lg-2 ">{t}</div>
						))}
					</div>
				</Col>
				<Col className="col-6 col-lg-1 d-flex  justify-content-start justify-content-lg-center  align-items-start  order-1 order-lg-4">
					<div className="d-flex flex-column justify-content-center align-items-center">
						<img
							src={`/icons/${details?.correct ? "correct" : "wrong"}_icon.png`}
							alt="icon-answer"
							style={{ width: "26px" }}
						/>
						<span
							style={{
								fontSize: "10px",
								lineHeight: "26px",
								color: details?.correct ? "#00E064" : "#FC4E28",
								fontWeight: 600,
							}}
						>
							{t(getLabel())}
						</span>
					</div>
				</Col>
				<Col
					className="col-6 col-lg-1 d-flex justify-content-end justify-content-lg-center align-items-start qst-text mt-2 order-2 order-lg-5 "
					style={{ color: "#82767e" }}
				>
					<span
						onClick={() => openSentryDialog()}
						style={{ cursor: "pointer" }}
					>
						{t("REPORT")}
					</span>
				</Col>
			</Row>
		</div>
	);
}

export default Question;
