import Question from "components/@score/question";
import React, { Dispatch, SetStateAction } from "react";
import { Modal } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

type TResultModalProps = {
	show: boolean;
	setShow: Dispatch<SetStateAction<boolean>>;
	data: any;
};

function ResultsModal({ show, setShow, data }: TResultModalProps) {
	const { t } = useTranslation();
	return (
		<Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
			<Container>
				<Modal.Header closeButton={true}>
					<Modal.Title>{t("YOUR_ANSWERS")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div
						style={{
							overflowY: "scroll",
							height: "calc(100vh - 70px - 2rem)",
						}}
					>
						{data.questions.map((q: any, i: number) => {
							const details = data.details[q._id];
							return (
								<Question
									question={q}
									index={i}
									details={details}
									setShow={setShow}
								/>
							);
						})}
					</div>
				</Modal.Body>
			</Container>
		</Modal>
	);
}

export default ResultsModal;
