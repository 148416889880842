import React, { useState } from "react";

export interface RatingProps {
  ratingValue: number | null;
  stars?: number;
  size?: number;
  fillColor?: string;
  emptyColor?: string;
  className?: string;
  children?: React.ReactNode;
}

interface EditableRatingProps extends RatingProps {
  onClick: (index: number) => void;
  transition?: boolean;
}

const reactons = [
  "./unhappy_face.png",
  "./unhappy_face.png",
  "./unhappy_face.png",
  "./unhappy_face.png",
  "./unhappy_face.png",
  "./unhappy_face.png",
  "./neutral_face.png",
  "./neutral_face.png",
  "./happy_face.png",
  "./happy_face.png",
];

export function Rating({
  ratingValue,
  onClick,
  stars = 5,
  size = 25,
  transition = false,
  fillColor = "#00FFFF",
  emptyColor = "transparent",
  className = "",
  children,
}: EditableRatingProps): JSX.Element {
  const [hoverValue, setHoverValue] = useState(ratingValue || null);

  return (
    <span className={className} style={{ display: "flex" }}>
      {[...Array(stars)].map((_, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "0.8rem",
          }}
        >
          <div
            style={{
              fontSize: 20,
              marginBottom: "10px",
              fontWeight:
                (hoverValue || ratingValue) &&
                (hoverValue || ratingValue) === index + 1
                  ? "bold"
                  : "300",
              color:
                (hoverValue || ratingValue) &&
                (hoverValue || ratingValue) === index + 1
                  ? "#00FFFF"
                  : "#ffffff",
            }}
          >
            {index + 1}
          </div>
          <div
            key={index}
            onMouseEnter={() => setHoverValue(index + 1)}
            onMouseLeave={() => setHoverValue(null)}
            onClick={() => onClick && onClick(index + 1)}
            aria-hidden="true"
            style={{
              // backgroundColor:
              //   (hoverValue || ratingValue) &&
              //   (hoverValue || ratingValue) === index + 1
              //     ? fillColor
              //     : emptyColor,
              boxShadow:
                (hoverValue || ratingValue) &&
                (hoverValue || ratingValue) === index + 1
                  ? `0px 0px 10px ${fillColor}`
                  : "none",
              color:
                (hoverValue || ratingValue) &&
                (hoverValue || ratingValue) !== index + 1
                  ? "#070a24"
                  : fillColor,
              // width: size,
              // height: size,
              cursor: "pointer",
              transition: transition
                ? "background-color 0.2s ease-in-out 0s"
                : "",
              display: "inline-flex",
              borderRadius: "50%",
              border:
                (hoverValue || ratingValue) &&
                (hoverValue || ratingValue) === index + 1
                  ? `1px solid ${fillColor}`
                  : "none",

              // display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{
                height: "55px",
              }}
              src={reactons[index]}
            />
          </div>
        </div>
      ))}
    </span>
  );
}
