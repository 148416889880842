import { atom } from "recoil";

export const ValidErrorTypes = [
  "NOT_FOUND",
  "INTERNAL_SERVER_ERROR",
  "UNVERIFIED_CANDIDATE",
  "UNAUTHORIZED",
  "CERTIFICATE_NOT_FOUND",
  "ASSIGNMENT_TIMED_OUT",
  "ASSIGNMENT_NOT_STARTED"
];

export const ErrorTypes = [
  "NOT_FOUND",
  "INTERNAL_SERVER_ERROR",
  "UNVERIFIED_CANDIDATE",
  "UNAUTHORIZED",
  "CERTIFICATE_NOT_FOUND",
  "ASSIGNMENT_TIMED_OUT",
] as const;

export type TErrorTypes = typeof ErrorTypes[number];

const errorStore = atom<TErrorTypes>({
  key: "error",
  default: "UNAUTHORIZED",
});

export default errorStore;
