import posthog from "posthog-js";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import * as Sentry from "@sentry/react"
import jwt_decode from "jwt-decode";

export interface IExamTokenPayload {
  assignmentId: string
  examId: string
  user: string
  type: string
  isEnrolled: boolean
  courseName: string
  className: string
  track: string
  userName: string,
  userEmail: string,
  lessonId: number
  courseId: number
  module: string
  moduleCode: string
  isTestingMode: boolean
  name: string
  email: string
  language: string,
  unlockDate: string
}

const { REACT_APP_POSTHOG_KEY = "" } = process.env;
export default function usePostHog() {
  const location = useLocation();
  const isLocalHost = window.location.hostname === "localhost";
  useEffect(() => {
    if(isLocalHost) return
    posthog.init(REACT_APP_POSTHOG_KEY, {
      api_host: "https://app.posthog.com",
      debug: true,
    });
  }, [isLocalHost]);

  
  React.useEffect(() => {
    if(isLocalHost) return
    const tokenInLocal = localStorage.getItem("token")

    if (tokenInLocal) {
      try {
        const decoded = jwt_decode<IExamTokenPayload>(tokenInLocal);
        if (decoded) {
          posthog.identify(decoded.email, decoded);
        }
      } catch (error) {
        Sentry.captureException(error)

        console.log(error)
      }
    }
  }, [isLocalHost, location]);

}

