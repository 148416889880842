import React from "react";

export type TTextProps = {
  text: string;
};
export default function Text({ text }: TTextProps) {
  return (
    <div>
      <h4
        className="mt-4 mb-4 question-text"
        style={{ wordWrap: "break-word" }}
      >
        {text}
      </h4>
    </div>
  );
}
