import { atom } from "recoil";

 
export type TAssignment = any

const assignmentStore = atom<TAssignment>({
  key: "assignment",
  default: {},
});

export default assignmentStore;
