import { useEffect, useState } from "react";

const useIframeCheck = () => {
  const [isIframe, setIsIframe] = useState(false);
  useEffect(() => {
    setIsIframe(window.location !== window.parent.location);
  }, []);

  return { isIframe };
};

export default useIframeCheck;
