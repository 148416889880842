import EpicodeNavbar from "components/@common/EpicodeNavbar";
import { ReactNode } from "react";
import { Container } from "react-bootstrap";
import "./MainLayout.css";

function MainLayout({ children,navbar }: { children: ReactNode,navbar:boolean }) {
  return (
    <Container fluid className="main-layout">
      <Container
        style={{ minHeight: "100vh" }}
        className="d-flex flex-column main-layout"
      >
        {navbar &&  <EpicodeNavbar />}
        <div className="main">{children}</div>
      </Container>
    </Container>
  );
}

export default MainLayout;
