import "./Loader.css";

function Loader() {
  return (
    <div
      className=" d-flex align-items-center justify-content-center"
      style={{ minHeight: "calc(100vh - 74px)" }}
    >
      <img src="logo.png" alt="logo" className="logo heartbeat" />
    </div>
  );
}

export default Loader;
