import React from "react";
import { useContext } from "react";
import { Button } from "react-bootstrap";
import { BsCheck2Circle, BsCircle } from "react-icons/bs";
import { QuestionContext, TQuestionContext } from "..";
import "./Option.css";

export type TOptionProps = {
  id: string;
  text: string;
  isCorrect?: boolean;
  style?: React.CSSProperties;
  selected?: boolean;
  totalOptions: number;
};

export default function Option({
  text,
  selected,
  style,
  id,
  totalOptions,
}: TOptionProps) {
  const { isSelected, selectOption } =
    useContext<TQuestionContext>(QuestionContext);
  const Icon = selected || isSelected(id) ? BsCheck2Circle : BsCircle;
  return (
    <Button
      variant={
        selected || isSelected(id)
          ? "epicode-option-button-selected"
          : "epicode-option-button"
      }
      onClick={() => selectOption(id)}
      className={`shadow-none py-3  h-100 ${
        isSelected(id) ? "blink-1" : ""
      } w-100 `}
    >
      <span style={{ wordWrap: "break-word" }}>{text}</span>
    </Button>
  );
}
